@font-face {
    font-family: 'Walsheim';
    font-weight: 700;
    src: local('Proto Grotesk'), url(./assets/fonts/GT-Walsheim-Pro-Bold.otf) format('opentype');
}

@font-face {
    font-family: 'Walsheim';
    font-weight: 400;
    src: local('Proto Grotesk'), url(./assets/fonts/GT-Walsheim-Pro-Medium.otf) format('opentype');
}